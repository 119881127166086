import React from 'react';
import PropTypes from 'utils/propTypes';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Media } from 'reactstrap';
import { AiFillCheckCircle } from 'react-icons/ai'
import Avatar from 'components/Avatar';

const Notifications = ({ notificationsData, userRole, currentUser }) => {
  let history = useHistory();

  async function markAsReadSubmit(e) {
    if (currentUser != "") {
      const response = await fetch('https://businessriver.tv/api/notification/'+e+'/read', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Content-Type': 'application/json',
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        body: JSON.stringify({user_id: currentUser}), // body data type must match "Content-Type" header
      })

      const json = await response.json();

      if (json.status == "Success") {
        //alert("Award updated successfully");
        

      } else {
        
      }
    } else {
      
    }
  }

  return (
    <>
      {notificationsData && notificationsData.length > 0 ?
        <>
          {notificationsData.map(({ id, avatar, name, subject, message, customer_url, admin_url, created_at, read_at, description}) => (
            <>
              {read_at ?
                <>
                  <Media key={id} className="font-style n notification-single read">
                    <Media left className="align-self-center pr-3">
                      <a href={userRole == "Admin" ? admin_url : customer_url}>
                        <Avatar tag={Media} object src={avatar} alt="Avatar" />
                      </a>
                    </Media>
                    <Media body middle className="align-self-center">
                      <a href={userRole == "Admin" ? admin_url : customer_url}>
                        <span className='font-weight-bold'>{subject ? subject : "-"}</span><br/>{message ? message : "-"}<br />
                        <small className="text-muted">{new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',  hour: '2-digit', minute:'2-digit' })}</small> <br />
                      </a>
                    </Media>
                  </Media>
                  <hr />
                </>
                :
                <>
                  <Media key={id} className="font-style n notification-single">
                    <Media left className="align-self-center pr-3">
                      <a href={userRole == "Admin" ? admin_url : customer_url}>
                        <Avatar tag={Media} object src={avatar} alt="Avatar" />
                      </a>
                    </Media>
                    <Media body middle className="align-self-center">
                      <a href={userRole == "Admin" ? admin_url : customer_url}>
                        <span className='font-weight-bold'>{subject ? subject : "-"}</span><br/>{message ? message : "-"}<br />
                        <small className="text-muted">{new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',  hour: '2-digit', minute:'2-digit' })}</small> <br />
                      </a>
                    </Media>
                    <Media right className="align-self-center">
                      <AiFillCheckCircle size="20" onClick={function (event) { markAsReadSubmit(id) }} />
                    </Media>
                  </Media>
                  <hr />
                </>
              }
            </>
            
          ))}
        </>
        :
        <Media className="font-style n notification-single">
          <Media body middle className="align-self-center text-center">
            No new notifications
          </Media>
        </Media>
      }
    </>
  );
};

Notifications.propTypes = {
  notificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.ID,
      avatar: PropTypes.string,
      name: PropTypes.string,
      message: PropTypes.node,
      date: PropTypes.date,
    })
  ),
};

Notifications.defaultProps = {
  notificationsData: [],
};

export default Notifications;
