import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import { STATE_BIS_LOGIN, STATE_BIS_SIGNUP } from 'components/BisAuthForm';
import { useLocation, Link, useHistory } from 'react-router-dom';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import BisAuthPage from 'pages/BisAuthPage';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import MaintenancePage from 'pages/MaintenancePage';
import React, {useState, useEffect} from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const AwardPage = React.lazy(() => import('pages/AwardPage'));
const CreateAwardPage = React.lazy(() => import('pages/CreateAwardPage'));
const SetupAwardPage = React.lazy(() => import('pages/SetupAwardPage'));
const EntryPage = React.lazy(() => import('pages/EntryPage'));
const EntryDefaultPage = React.lazy(() => import('pages/EntryDefaultPage'));
const EntryDefaultTestPage = React.lazy(() => import('pages/EntryDefaultTestPage'));
const ProfileViewPage = React.lazy(() => import('pages/ProfileViewPage'));
const CategoryPage = React.lazy(() => import('pages/CategoryPage'));
const BisSetupAwardPage = React.lazy(() => import('pages/BisSetupAwardPage'));
const Booking = React.lazy(() => import('pages/Booking'));
const BookingUnregistered = React.lazy(() => import('pages/BookingUnregistered'));
const BookingUnlisted = React.lazy(() => import('pages/BookingUnlisted'));
const BisSingleAwardPage = React.lazy(() => import('pages/BisSingleAwardPage'));
const BookingSuccess = React.lazy(() => import('pages/BookingSuccess'));
const BookingWinnerSuccess = React.lazy(() => import('pages/BookingWinnerSuccess'));
const SingleEntryPage = React.lazy(() => import('pages/SingleEntryPage'));
const AdminSingleEntryPage = React.lazy(() => import('pages/AdminSingleEntryPage'));
const EditEntryPage = React.lazy(() => import('pages/EditEntryPage'));
const BookingDetailsPage = React.lazy(() => import('pages/BookingDetailsPage'));
const CheckOutPage = React.lazy(() => import('pages/CheckOutPage'));
const CheckOutUnlistedPage = React.lazy(() => import('pages/CheckOutUnlistedPage'));
const BookingsPage = React.lazy(() => import('pages/BookingsPage'));
const BookingAttendeesPage = React.lazy(() => import('pages/BookingAttendeesPage'));
const DashboardEntrantPage = React.lazy(() => import('pages/DashboardEntrantPage'));
const AdminAwardPage = React.lazy(() => import('pages/AdminAwardPage'));
// const AdminSingleAwardPage = React.lazy(() => import('pages/AdminSingleAwardPage'));
const AdminUserPage = React.lazy(() => import('pages/AdminUserPage'));
const AdminJudgePage = React.lazy(() => import('pages/AdminJudgePage'));
const AdminCoordinatorPage = React.lazy(() => import('pages/AdminCoordinatorPage'));
const EntriesPage = React.lazy(() => import('pages/EntriesPage'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage'));
const EditProfilePage = React.lazy(() => import('pages/EditProfilePage'));
const BookingAttendeesSetupPage = React.lazy(() => import('pages/BookingAttendeesSetupPage'));
const AdminDashboardPage = React.lazy(() => import('pages/AdminDashboardPage'));
const AdminUserManagementPage = React.lazy(() => import('pages/AdminUserManagementPage'));
const AdminAwardsV2Page = React.lazy(() => import('pages/AdminAwardsV2Page'));
const AdminSingleAwardPage = React.lazy(() => import('pages/AdminSingleAwardPage'));
const AdminSingleCategoryPage = React.lazy(() => import('pages/AdminSingleCategoryPage'));
const AdminEntriesPage = React.lazy(() => import('pages/AdminEntriesPage'));
const CreateCategoryPage = React.lazy(() => import('pages/CreateCategoryPage'));
const AdminBookingsPage = React.lazy(() => import('pages/AdminBookingsPage'));
const ViewProfilePage = React.lazy(() => import('pages/ViewProfilePage'));
const AdminBookingDetailsPage = React.lazy(() => import('pages/AdminBookingDetailsPage'));
const JudgeDashboardPage = React.lazy(() => import('pages/JudgeDashboardPage'));
const JudgeProfilePage = React.lazy(() => import('pages/JudgeProfilePage'));
const CoordinatorDashboardPage = React.lazy(() => import('pages/CoordinatorDashboardPage'));
const CoordinatorProfilePage = React.lazy(() => import('pages/CoordinatorProfilePage'));
const JudgeCategoryPage = React.lazy(() => import('pages/JudgeCategoryPage'));
const JudgeEntryPage = React.lazy(() => import('pages/JudgeEntryPage'));
const JudgeAssignmentsPage = React.lazy(() => import('pages/JudgeAssignmentsPage'));
const JudgeAssignmentPage = React.lazy(() => import('pages/JudgeAssignmentPage'));
const JudgeAssignmentCategoryPage = React.lazy(() => import('pages/JudgeAssignmentCategoryPage'));
const CoordinatorCategoryPage = React.lazy(() => import('pages/CoordinatorCategoryPage'));
const CoordinatorAssignmentsPage = React.lazy(() => import('pages/CoordinatorAssignmentsPage'));
const CoordinatorAssignmentPage = React.lazy(() => import('pages/CoordinatorAssignmentPage'));
const CoordinatorAssignmentCategoryPage = React.lazy(() => import('pages/CoordinatorAssignmentCategoryPage'));
const InvoicesPage = React.lazy(() => import('pages/InvoicesPage'));
const AdminJudgingResults = React.lazy(() => import('pages/AdminJudgingResults'));
const AdminsPage = React.lazy(() => import('pages/AdminsPage'));
const BookingAwardWinner = React.lazy(() => import('pages/BookingAwardWinner'));
const CheckOutAwardWinnerPage = React.lazy(() => import('pages/CheckOutAwardWinnerPage'));
const AdminEntryEditPage = React.lazy(() => import('pages/AdminEntryEditPage'));
const AdminsV2Page = React.lazy(() => import('pages/AdminsV2Page'));
const NotificationPage = React.lazy(() => import('pages/NotificationPage'));
const AdminInvoicesPage = React.lazy(() => import('pages/AdminInvoicesPage'));
const BookingAwardEventPage = React.lazy(() => import('pages/BookingAwardEventPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

function AppMain(props) {
  const breakpoint = props.breakpoint;
  const loggedIn = useState(sessionStorage.getItem("loggedIn"));
  
  //const loggedIn = "true";
  return (
    <BrowserRouter basename={getBasename()}>
      <GAListener>
        <Switch>
          {/* <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          /> */}
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/maintenance"
            layout={EmptyLayout}
            component={props => (
              <MaintenancePage />
            )}
          />
          <LayoutRoute
            exact
            path="/forgotpassword"
            layout={EmptyLayout}
            component={props => (
              <ForgetPasswordPage />
            )}
          />
          <LayoutRoute
            exact
            path="/register"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_SIGNUP} />
            )}
          />
          <MainLayout breakpoint={breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/dashboard" component={DashboardPage} />
              <Route exact path="/login-modal" component={AuthModalPage} />
              <Route exact path="/buttons" component={ButtonPage} />
              <Route exact path="/cards" component={CardPage} />
              <Route exact path="/widgets" component={WidgetPage} />
              <Route exact path="/typography" component={TypographyPage} />
              <Route exact path="/alerts" component={AlertPage} />
              <Route exact path="/tables" component={TablePage} />
              <Route exact path="/badges" component={BadgePage} />
              <Route
                exact
                path="/button-groups"
                component={ButtonGroupPage}
              />
              <Route exact path="/dropdowns" component={DropdownPage} />
              <Route exact path="/progress" component={ProgressPage} />
              <Route exact path="/modals" component={ModalPage} />
              <Route exact path="/forms" component={FormPage} />
              <Route exact path="/input-groups" component={InputGroupPage} />
              <Route exact path="/charts" component={ChartPage} />
              <Route exact path="/award/details" component={SetupAwardPage} />
              <Route exact path="/admin/award/create" component={CreateAwardPage} />
              <Route exact path="/award/entry" component={EntryPage} />
              <Route exact path="/profile-old" component={ProfileViewPage} />
              <Route exact path="/profile" component={ProfilePage} />
              <Route exact path="/edit-profile" component={EditProfilePage} />
              <Route exact path="/award/category" component={CategoryPage} />
              <Route exact path="/award/bis-setup" component={BisSetupAwardPage} />
              <Route exact path="/award/bis-single-award" component={BisSingleAwardPage} />
              <Route exact path="/award/booking" component={Booking} />
              <Route exact path="/award/book" component={BookingUnregistered} />
              <Route exact path="/award/book/seats" component={BookingUnlisted} />
              <Route exact path="/award/winner" component={BookingAwardWinner} />
              <Route exact path="/booking/payment/success" component={BookingSuccess} />
              <Route exact path="/winner/payment/success" component={BookingWinnerSuccess} />
              <Route exact path="/entry" component={SingleEntryPage} />
              <Route exact path="/admin/entry" component={AdminSingleEntryPage} />
              <Route exact path="/award/default-entry" component={EntryDefaultPage} />
              <Route exact path="/award/defaulttest-entry" component={EntryDefaultTestPage} />
              {/* <Route exact path="/entry/edit" component={EditEntryPage} /> */}
              <Route exact path="/entry/edit" component={EntryPage} />
              <Route exact path="/booking/details" component={BookingDetailsPage} />
              <Route exact path="/booking/summary" component={CheckOutPage} />
              <Route exact path="/booking/summary/unlisted" component={CheckOutUnlistedPage} />
              <Route exact path="/winner/summary" component={CheckOutAwardWinnerPage} />
              <Route exact path="/booking/attendees" component={BookingAttendeesPage} />
              <Route exact path="/booking/attendees/setup" component={BookingAttendeesSetupPage} />
              <Route exact path="/" component={DashboardEntrantPage} />
              {/* <Route exact path="/admin/awards" component={AdminAwardPage} /> */}
              {/* <Route exact path="/admin/award-single-page" component={AdminSingleAwardPage} /> */}
              <Route exact path="/admin/user" component={AdminUserPage} />
              <Route exact path="/admin/judges" component={AdminJudgePage} />
              <Route exact path="/admin/coordinators" component={AdminCoordinatorPage} />
              <Route exact path="/awards" component={AwardPage} />
              <Route exact path="/entries" component={EntriesPage} />
              <Route exact path="/bookings" component={BookingsPage} />
              <Route exact path="/admin" component={AdminDashboardPage} />
              <Route exact path="/admin/users" component={AdminUserManagementPage} />
              <Route exact path="/admin/awards" component={AdminAwardsV2Page} />
              <Route exact path="/admin/award" component={AdminSingleAwardPage} />
              <Route exact path="/admin/category" component={AdminSingleCategoryPage} />
              <Route exact path="/admin/category/create" component={CreateCategoryPage} />
              <Route exact path="/admin/entries" component={AdminEntriesPage} />
              <Route exact path="/admin/bookings" component={AdminBookingsPage} />
              <Route exact path="/admin/booking/details" component={AdminBookingDetailsPage} />
              <Route exact path="/admin/view-profile" component={ViewProfilePage} />
              <Route exact path="/judge/dashboard" component={JudgeDashboardPage} />
              <Route exact path="/judge/profile" component={JudgeProfilePage} />
              <Route exact path="/coordinator/dashboard" component={CoordinatorDashboardPage} />
              <Route exact path="/coordinator/profile" component={CoordinatorProfilePage} />
              <Route exact path="/judge/category" component={JudgeCategoryPage} />
              <Route exact path="/judge/entry" component={JudgeEntryPage} />
              <Route exact path="/judge/assignments" component={JudgeAssignmentsPage} />
              <Route exact path="/judge/assignment" component={JudgeAssignmentPage} />
              <Route exact path="/judge/assignment/category" component={JudgeAssignmentCategoryPage} />
              <Route exact path="/coordinator/assignments" component={CoordinatorAssignmentsPage} />
              <Route exact path="/coordinator/assignment" component={CoordinatorAssignmentPage} />
              <Route exact path="/coordinator/assignment/category" component={CoordinatorAssignmentCategoryPage} />
              <Route exact path="/coordinator/category" component={CoordinatorCategoryPage} />
              <Route exact path="/invoices" component={InvoicesPage} />
              <Route exact path="/admin/judging-results" component={AdminJudgingResults} />
              <Route exact path="/admins" component={AdminsPage} />
              <Route exact path="/admin/entry/edit" component={AdminEntryEditPage} />
              <Route exact path="/admin/admins" component={AdminsV2Page} />
              <Route exact path="/notifications" component={NotificationPage} />
              <Route exact path="/admin/invoices" component={AdminInvoicesPage} />
              <Route exact path="/award/event" component={BookingAwardEventPage} />
              
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </GAListener>
    </BrowserRouter>
  );
}


const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(AppMain);
