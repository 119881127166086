import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React, {useState, useEffect} from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  MdImportantDevices,
  // MdCardGiftcard,
  MdLoyalty,
} from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

//class MainLayout extends React.Component {
class MainLayout extends React.Component {
  
  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  componentWillReceiveProps({ breakpoint }) {
    /*if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }*/
  }

  componentDidMount() {
    /*this.checkBreakpoint(this.props.breakpoint);*/
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const welcomeMessage = "Welcome User";
    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      this.notificationSystem.addNotification({
        title: <MdImportantDevices />,
        message: welcomeMessage,
        level: 'info',
      });
    }, 1500);

    /*setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      this.notificationSystem.addNotification({
        title: <MdLoyalty />,
        message:
          'Reduction is carefully designed template powered by React and Bootstrap4!',
        level: 'info',
      });
    }, 2500);*/
  }

  // close sidebar when
  /*handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }*/
  
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: sessionStorage.getItem("loggedIn")
    };
  }
  render() {
    const { children } = this.props;
    let loggedIn = this.state.loggedIn;
    //const loggedIn = "we";
    return (
      <main className="cr-app bg-light">
        {/*<Sidebar />*/}
        <Content fluid onClick={this.handleContentClick}>
          <Header />
          {children}
          <Footer />
        </Content>

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </main>
    );
  }
}

export default MainLayout;
