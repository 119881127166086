import React, { Component, useEffect, useState } from 'react';

import PropTypes from 'utils/propTypes';

import classNames from 'classnames';

import { NavLink, Col, Row, Card, CardTitle, CardSubtitle, CardText, CardBody } from 'reactstrap';
// import { useHistory } from 'react-router-dom';
import Avatar from '../Avatar';

import { useLocation } from 'react-router-dom'
import axios from 'axios';

// import {SingleEntryPage} from '.../pages/SingleEntryPage';
// import { render } from 'node-sass';
import { MdOutlineArrowBack } from 'react-icons/md'

const initialEntryData = Object.freeze({
  first_name: "",
});


// const [singleEntry, setSingleEntry] = useState();
// const [entryData, setEntryData] = useState(initialEntryData);

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// }
// let query = useQuery();
// const entry_id = query.get('entry_id');

// const retrieveSingleEntry = async () => {
//   const response = fetch('https://businessriver.tv/api/entry/' + entry_id, {
//     method: 'GET', // *GET, POST, PUT, DELETE, etc.
//     mode: 'cors', // no-cors, cors, *same-origin
//     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//     credentials: 'omit', // include, *same-origin, omit
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Credentials": true,
//       'Content-Type': 'application/json',
//       "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
//       "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
//     },
//     redirect: 'follow', // manual, *follow, error
//     referrer: 'client', // no-referrer, *client
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .then((events) => {
//       return events.data;
//     });
//   return response;
// };



const UserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const classes = classNames('bg-gradient-theme', className);


  return (
    // <Card inverse className={classes} {...restProps}>
    //   <CardBody className="d-flex justify-content-center align-items-center flex-column">
    //     <Avatar src={avatar} size={avatarSize} className="mb-2" />
    //     <CardTitle>{title}</CardTitle>
    //     <CardSubtitle>{subtitle}</CardSubtitle>
    //     <CardText>
    //       <small>{text}</small>
    //     </CardText>
    //   </CardBody>
    //   {children}
    // </Card>
    <Card inverse className={classes} {...restProps} >
      <CardBody className="cardbgcolor pb-0">
        <Row>
          <Col lg={4}>
            <NavLink className="useravatar" href="/profile">
              <Avatar src={avatar} size={avatarSize} style={{ height: "100%", width: "50px" }} className="useravatarborder mb-2" />
            </NavLink>
          </Col>
          <Col lg={8} className="mt-0">
            <CardTitle className="profilename">Hi, {title}!</CardTitle>
              <CardSubtitle className="profilesubtitle text-dark">{subtitle}<MdOutlineArrowBack/>See your profile</CardSubtitle>
            {/* <CardText>
          <small>{text}See your profile</small>
        </CardText> */}
          </Col>
        </Row>
      </CardBody>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCard.defaultProps = {
  avatarSize: 80,
};


export default UserCard;
