import React, { useState } from 'react';
import BISlogoblue from 'assets/img/bis/logo2.png'
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import validator from 'validator'

const initialFormDataRegister = Object.freeze({
  email: '',
  password: '',
  password_confirmation: '',
  company_name: '',
  company_position: '',
  company_industry: 'Accounting',
  company_email: '',
  company_phone: '',
  first_name: '',
  last_name: '',
  contact_phone: '',
  contact_email: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  province: '',
  zip: '',
  country: '',
});

const initialFormDataLogin = Object.freeze({
  email: '',
  password: '',
})

//class AuthForm extends React.Component {
function BisAuthForm(props) {
  let history = useHistory();
  const [formDataRegister, setFormDataRegister] = useState(initialFormDataRegister)
  const [formDataLogin, setFormDataLogin] = useState(initialFormDataLogin)
  const [formStatus, setFormStatus] = useState("standby");
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn']);
  // const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const currentUser = cookies.currentUser;
  // const isLoggedIn = sessionStorage.getItem("IsLoggedIn");
  const isLoggedIn = cookies.isLoggedIn;

  const {
    showLogo,
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
    children,
    onLogoClick,
  } = props;

  const [isLogin, setIsLogin] = useState(props.authState === STATE_BIS_LOGIN)

  const [isSignup, setIsSignup] = useState(props.authState === STATE_BIS_SIGNUP)

  const changeAuthState = authState => event => {
    event.preventDefault();
    props.onChangeAuthState(authState);
  };

  /*handleSubmit = event => {
    event.preventDefault();
  };*/

  const renderButtonText = (e) => {
    const { buttonText } = props;
    if (!buttonText && isLogin) {
      return 'Login';
    }

    if (!buttonText && isSignup) {
      return 'Register';
    }
    return buttonText;
  }

  const handleChange = (e) => {
    if (isLogin === true) {
      setFormDataLogin({
        ...formDataLogin,
        [e.target.name]: e.target.value,
      })
    } else {
      setFormDataRegister({
        ...formDataRegister,
        [e.target.name]: e.target.value,
      })
    }
    //console.log(formDataLogin);
    //console.log(formDataRegister);
  }

  if (!currentUser) {

  } else {
    history.push("/");
  }


  async function loginSubmit(e) {
    e.preventDefault();
    setFormStatus("loading");
    if (validator.isEmail(formDataLogin.email)) {
      const response = await fetch("https://businessriver.tv/api/login", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify(formDataLogin),
      });

      const json = await response.json();
      if (json.status == "Success") {
        // console.log(json);
        setFormStatus("standby");
        setCookies('currentUser', JSON.stringify(json.data.id), { path: '/' });
        setCookies('isLoggedIn', true, { path: '/' });
        setCookies('userRole', json.data.user.role, { path: '/' });
        setCookies('judge', json.data.user.judge, { path: '/' });
        setCookies('judge_id', json.data.user.judge_id, { path: '/' });
        setCookies('coordinator_id', json.data.user.coordinator_id, { path: '/' });
        if (json.data.user.role == "Admin") {
          history.push("/admin");
        } else {
          history.push("/");
        }

        // toast.success('Login success!', {
        //   position: "top-right", 
        //   autoClose: 1000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   onClose: () => {
        //     history.push("/");
        //     setCookies('currentUser', JSON.stringify(json.data.id), {path:'/'});
        //     setCookies('isLoggedIn', true, {path:'/'});
        //     setCookies('userRole', json.data.user.role, {path:'/'});
        //   }
        // });
      } else {
        //console.log(json);
        if (json.errors.email) {
          toast.error(json.errors.email[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } if (json.errors.password) {
          toast.error(json.errors.password[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(json.errors[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setFormStatus("standby");
      }
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    } 
  }

  async function signupSubmit(e) {
    e.preventDefault();
    setFormStatus("loading");
    if (validator.isEmail(formDataRegister.email)) {
      if (formDataRegister.password != "" && formDataRegister.password_confirmation != ""
       && formDataRegister.first_name != ""  && formDataRegister.last_name != ""  && formDataRegister.company_name != ""  
       && formDataRegister.company_position != ""  && formDataRegister.company_industry != "" && formDataRegister.company_phone != "" ) {
        const response = await fetch("https://businessriver.tv/api/register", {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "omit",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrer: "client",
          body: JSON.stringify(formDataRegister),
        });

        const json = await response.json();
        //console.log(json);
        if (json.status == "Success") {
          setCookies('currentUser', JSON.stringify(json.data.id), { path: '/' });
          setCookies('isLoggedIn', true, { path: '/' });
          setCookies('userRole', JSON.stringify(json.data.role), { path: '/' });
          toast.success('Registered successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
              history.push("/");
              setCookies('currentUser', JSON.stringify(json.data.id), { path: '/' });
              setCookies('isLoggedIn', true, { path: '/' });
              setCookies('userRole', json.data.user.role, { path: '/' });
              setCookies('judge', json.data.user.judge, { path: '/' });
              setCookies('judge_id', json.data.user.judge_id, { path: '/' });
              setCookies('coordinator_id', json.data.user.coordinator_id, { path: '/' });
            }
          });
          //history.push("/");
          setFormStatus("standby");
        } else {
          if (json.errors.email) {
            toast.error(json.errors.email[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } if (json.errors.password) {
            toast.error(json.errors.password[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(json.errors[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setFormStatus("standby");
        }
      } else {
        toast.error("Please fill up all fields!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFormStatus("standby");
      }
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    }

  }
  return (

    <div id="bis-form">
      <ToastContainer style={{ width: "370px" }} />
      {isLogin && (
        <Form onSubmit={loginSubmit} className="bis_login_form">
          {/* {showLogo && (
            <div className="text-center pb-4">
              <a href="/">
                <img
                  src={VEP}
                  className="rounded"
                  style={{ height: 60 }}
                  alt="logo"
                  onClick={onLogoClick}
                />
              </a>
            </div>
          )} */}
          <p className="text-center form_login">MEMBER LOGIN</p>
          <FormGroup>
            {/* <Label for={usernameLabel}>{usernameLabel}</Label> */}
            <Input {...usernameInputProps} name="email" placeholder="Email Address" className="mb-3" required onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
            <Input {...passwordInputProps} name="password" placeholder="Password" className="mb-3" required onChange={handleChange} />
          </FormGroup>
          {/* {isLogin && (
            <a href="#" className="forget_password">Forget Password</a>
          )} */}
          {formStatus != "standby" ?
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block>
              Logging in...
            </Button>
            :
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block
              onClick={isLogin ? loginSubmit : signupSubmit}>
              {renderButtonText()}
            </Button>
          }
          <div className='text-center'>
            <a className='bis-link' href='/forgotpassword'>Forgot password?</a>
          </div>
          <div className="text-center mt-1">
            <a href="#bis-signup" className="bis-link" onClick={changeAuthState(STATE_BIS_SIGNUP)}>
              Register
            </a>
          </div>
        </Form>
      )}

      {isSignup && (
        <Form onSubmit={signupSubmit} className="bis_signup_form container">
          {/* {showLogo && (
            <div className="text-center pb-4">
              <img
                src={VEP}
                className="rounded"
                style={{ height: 60 }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )} */}
          <div className='text-center mt-3 mb-3'>
            <img
              src={BISlogoblue}
              className="rounded"
              style={{ height: 60 }}
              alt="logo"
            />
          </div>
          <p className="text-center form_login mb-0">MEMBER REGISTRATION</p>
          {/* <p className="small text-muted text-center mb-4">Fields with <span className="asterisk_red">*</span>  are required</p> */}
          <h3>LOGIN INFORMATION</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <FormGroup>
                <p className='small mb-1'>Email Address<span className="asterisk_red">*</span> </p>
                {/* <Input {...usernameInputProps} type="text" name="username" className="mb-3" onChange={handleChange} /> */}
                <Input type="email" name="email" className="mb-3" onChange={handleChange} />
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <p className='small mb-1'>Password<span className="asterisk_red">*</span></p>
                    <Input type="password" name="password" onChange={handleChange} />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <p className='small mb-1'>Confirm Password<span className="asterisk_red">*</span></p>
                    <Input type="password" name="password_confirmation" onChange={handleChange} />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <h3>PERSONAL INFORMATION</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <p className='small mb-1'>First Name<span className="asterisk_red">*</span></p>
              <Input type="text" name="first_name" onChange={handleChange} required/>
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Last Name<span className="asterisk_red">*</span></p>
              <Input type="text" name="last_name" onChange={handleChange} required/>
            </Col>
          </Row>
          <h3>COMPANY DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <p className='small mb-1'>Company Name<span className="asterisk_red">*</span></p>
              <Input type="text" name="company_name" className="mb-3" onChange={handleChange} required/>
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Position/Job Title<span className="asterisk_red">*</span></p>
              <Input type="text" name="company_position" className="mb-3" onChange={handleChange} required/>
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Industry<span className="asterisk_red">*</span></p>
              <Input type="select" name="company_industry" onChange={handleChange} required>
                <option value="Accounting">Accounting</option>
                <option value="Airlines/Aviation">Airlines/Aviation</option>
                <option value="Alternative Dispute Resolution">Alternative Dispute Resolution</option>
                <option value="Alternative Medicine">Alternative Medicine</option>
                <option value="Animation">Animation</option>
                <option value="Apparel/Fashion">Apparel/Fashion</option>
                <option value="Architecture/Planning">Architecture/Planning</option>
                <option value="Arts/Crafts">Arts/Crafts</option>
                <option value="Automotive">Automotive</option>
                <option value="Aviation/Aerospace">Aviation/Aerospace</option>
                <option value="Banking/Mortgage">Banking/Mortgage</option>
                <option value="Biotechnology/Greentech">Biotechnology/Greentech</option>
                <option value="Broadcast Media">Broadcast Media</option>
                <option value="Building Materials">Building Materials</option>
                <option value="Business Supplies/Equipment">Business Supplies/Equipment</option>
                <option value="Capital Markets/Hedge Fund/Private Equity">Capital Markets/Hedge Fund/Private Equity</option>
                <option value="Chemicals">Chemicals</option>
                <option value="Civic/Social Organization">Civic/Social Organization</option>
                <option value="Civil Engineering">Civil Engineering</option>
                <option value="Commercial Real Estate">Commercial Real Estate</option>
                <option value="Computer Games">Computer Games</option>
                <option value="Computer Hardware">Computer Hardware</option>
                <option value="Computer Networking">Computer Networking</option>
                <option value="Computer Software/Engineering">Computer Software/Engineering</option>
                <option value="Computer/Network Security">Computer/Network Security</option>
                <option value="Construction">Construction</option>
                <option value="Consumer Electronics">Consumer Electronics</option>
                <option value="Consumer Goods">Consumer Goods</option>
                <option value="Consumer Services">Consumer Services</option>
                <option value="Cosmetics">Cosmetics</option>
                <option value="Dairy">Dairy</option>
                <option value="Defense/Space">Defense/Space</option>
                <option value="Design">Design</option>
                <option value="E-Learning">E-Learning</option>
                <option value="Education Management">Education Management</option>
                <option value="Electrical/Electronic Manufacturing">Electrical/Electronic Manufacturing</option>
                <option value="Entertainment/Movie Production">Entertainment/Movie Production</option>
                <option value="Environmental Services">Environmental Services</option>
                <option value="Events Services">Events Services</option>
                <option value="Executive Office">Executive Office</option>
                <option value="Facilities Services">Facilities Services</option>
                <option value="Farming">Farming</option>
                <option value="Financial Services">Financial Services</option>
                <option value="Fine Art">Fine Art</option>
                <option value="Fishery">Fishery</option>
                <option value="Food Production">Food Production</option>
                <option value="Food/Beverages">Food/Beverages</option>
                <option value="Fundraising">Fundraising</option>
                <option value="Furniture">Furniture</option>
                <option value="Gambling/Casinos">Gambling/Casinos</option>
                <option value="Glass/Ceramics/Concrete">Glass/Ceramics/Concrete</option>
                <option value="Government Administration">Government Administration</option>
                <option value="Government Relations">Government Relations</option>
                <option value="Graphic Design/Web Design">Graphic Design/Web Design</option>
                <option value="Health/Fitness">Health/Fitness</option>
                <option value="Higher Education/Acadamia">Higher Education/Acadamia</option>
                <option value="Hospital/Health Care">Hospital/Health Care</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Human Resources/HR">Human Resources/HR</option>
                <option value="Import/Export">Import/Export</option>
                <option value="Individual/Family Services">Individual/Family Services</option>
                <option value="Industrial Automation">Industrial Automation</option>
                <option value="Information Services">Information Services</option>
                <option value="Information Technology/IT">Information Technology/IT</option>
                <option value="Insurance">Insurance</option>
                <option value="International Affairs">International Affairs</option>
                <option value="International Trade/Development">International Trade/Development</option>
                <option value="Internet">Internet</option>
                <option value="Investment Banking/Venture">Investment Banking/Venture</option>
                <option value="Investment Management/Hedge Fund/Private Equity">Investment Management/Hedge Fund/Private Equity</option>
                <option value="Judiciary">Judiciary</option>
                <option value="Law Enforcement">Law Enforcement</option>
                <option value="Law Practice/Law Firms">Law Practice/Law Firms</option>
                <option value="Legal Services">Legal Services</option>
                <option value="Legislative Office">Legislative Office</option>
                <option value="Leisure/Travel">Leisure/Travel</option>
                <option value="Library">Library</option>
                <option value="Logistics/Procurement">Logistics/Procurement</option>
                <option value="Luxury Goods/Jewelry">Luxury Goods/Jewelry</option>
                <option value="Machinery">Machinery</option>
                <option value="Management Consulting">Management Consulting</option>
                <option value="Maritime">Maritime</option>
                <option value="Market Research">Market Research</option>
                <option value="Marketing/Advertising/Sales">Marketing/Advertising/Sales</option>
                <option value="Mechanical or Industrial Engineering">Mechanical or Industrial Engineering</option>
                <option value="Media Production">Media Production</option>
                <option value="Medical Equipment">Medical Equipment</option>
                <option value="Medical Practice">Medical Practice</option>
                <option value="Mental Health Care">Mental Health Care</option>
                <option value="Military Industry">Military Industry</option>
                <option value="Mining/Metals">Mining/Metals</option>
                <option value="Motion Pictures/Film">Motion Pictures/Film</option>
                <option value="Museums/Institutions">Museums/Institutions</option>
                <option value="Music">Music</option>
                <option value="Nanotechnology">Nanotechnology</option>
                <option value="Newspapers/Journalism">Newspapers/Journalism</option>
                <option value="Non-Profit/Volunteering">Non-Profit/Volunteering</option>
                <option value="Oil/Energy/Solar/Greentech">Oil/Energy/Solar/Greentech</option>
                <option value="Online Publishing">Online Publishing</option>
                <option value="Other Industry">Other Industry</option>
                <option value="Outsourcing/Offshoring">Outsourcing/Offshoring</option>
                <option value="Package/Freight Delivery">Package/Freight Delivery</option>
                <option value="Packaging/Containers">Packaging/Containers</option>
                <option value="Paper/Forest Products">Paper/Forest Products</option>
                <option value="Performing Arts">Performing Arts</option>
                <option value="Pharmaceuticals">Pharmaceuticals</option>
                <option value="Philanthropy">Philanthropy</option>
                <option value="Photography">Photography</option>
                <option value="Plastics">Plastics</option>
                <option value="Political Organization">Political Organization</option>
                <option value="Primary/Secondary Education">Primary/Secondary Education</option>
                <option value="Printing">Printing</option>
                <option value="Professional Training">Professional Training</option>
                <option value="Program Development">Program Development</option>
                <option value="Public Relations/PR">Public Relations/PR</option>
                <option value="Public Safety">Public Safety</option>
                <option value="Publishing Industry">Publishing Industry</option>
                <option value="Railroad Manufacture">Railroad Manufacture</option>
                <option value="Ranching">Ranching</option>
                <option value="Real Estate/Mortgage">Real Estate/Mortgage</option>
                <option value="Recreational Facilities/Services">Recreational Facilities/Services</option>
                <option value="Religious Institutions">Religious Institutions</option>
                <option value="Renewables/Environment">Renewables/Environment</option>
                <option value="Research Industry">Research Industry</option>
                <option value="Restaurants">Restaurants</option>
                <option value="Retail Industry">Retail Industry</option>
                <option value="Security/Investigations">Security/Investigations</option>
                <option value="Semiconductors">Semiconductors</option>
                <option value="Shipbuilding">Shipbuilding</option>
                <option value="Sporting Goods">Sporting Goods</option>
                <option value="Sports">Sports</option>
                <option value="Staffing/Recruiting">Staffing/Recruiting</option>
                <option value="Supermarkets">Supermarkets</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Textiles">Textiles</option>
                <option value="Think Tanks">Think Tanks</option>
                <option value="Tobacco">Tobacco</option>
                <option value="Translation/Localization">Translation/Localization</option>
                <option value="Transportation">Transportation</option>
                <option value="Utilities">Utilities</option>
                <option value="Venture Capital/VC">Venture Capital/VC</option>
                <option value="Veterinary">Veterinary</option>
                <option value="Warehousing">Warehousing</option>
                <option value="Wholesale">Wholesale</option>
                <option value="Wine/Spirits">Wine/Spirits</option>
                <option value="Wireless">Wireless</option>
                <option value="Writing/Editing">Writing/Editing</option>
              </Input>
            </Col>
            {/* <Col lg={6}>
              <p className='small mb-1'>Company Email Address<span className="asterisk_red">*</span></p>
              <Input type="email" name="company_email" className="mb-3" onChange={handleChange} required/>
            </Col> */}
            <Col lg={12}>
              <p className='small mb-1'>Company Phone Number<span className="asterisk_red">*</span></p>
              <Input type="tel" name="company_phone" onChange={handleChange} required/>
            </Col>
          </Row>
          {/* <h3>ADDRESS DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <p className='small mb-1'>Address Line 1</p>
              <Input type="text" name="address_line_1" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={12}>
              <p className='small mb-1'>Address Line 2</p>
              <Input type="text" name="address_line_2" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>City</p>
              <Input type="text" name="city" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Province/City</p>
              <Input type="text" name="province" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Postal Code</p>
              <Input type="text" name="zip" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Country</p>
              <Input type="text" name="country" onChange={handleChange} />
            </Col>
          </Row>
          <h3>CONTACT DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <p className='small mb-1'>Phone Number</p>
              <Input type="tel" name="contact_phone" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Mobile Number</p>
              <Input type="tel" name="contact_mobile" onChange={handleChange} />
            </Col>
          </Row> */}
          {formStatus != "standby" ?
            <>
              {isLogin ?
                <Button
                  size="lg"
                  className="border-0 mt-4"
                  block
                  type="submit"
                >
                  Logging in...
                </Button>
                :
                <Button
                  size="lg"
                  className="border-0 mt-4"
                  block
                  type="submit"
                >
                  Signing up...
                </Button>
              }
            </>
            :
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block
              onClick={isLogin ? loginSubmit : signupSubmit}>
              {renderButtonText()}
            </Button>
          }
          <div className="text-center">
            <span className="mr-2">Already have an account?</span>
            <a href="/login" className="bis-link" onClick={changeAuthState(STATE_BIS_LOGIN)}>
              Login
            </a>
          </div>
        </Form>
      )}
      {/* <div className="text-center pt-1">
        <h6>
          {isSignup ? (
            <a href="#bis-login" className="bis-link" onClick={changeAuthState(STATE_BIS_LOGIN)}>
              Login
            </a>
          ) : (
            <a href="#bis-signup" className="bis-link" onClick={changeAuthState(STATE_BIS_SIGNUP)}>
              Register
            </a>
          )}
        </h6>
      </div> */}
      {children}
    </div>

  );
}


export const STATE_BIS_LOGIN = 'LOGIN';
export const STATE_BIS_SIGNUP = 'SIGNUP';

BisAuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_BIS_LOGIN, STATE_BIS_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

BisAuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password',
  },
  onLogoClick: () => { },
};

export default BisAuthForm;
