import React, { useEffect, useState } from "react";
import Page from "components/Page";
import BisAuthForm, { STATE_BIS_LOGIN } from "components/BisAuthForm";
import { Card, Col, Row, Container, Button } from "reactstrap";
import PharmacyAward from "assets/img/bis/pharmacy-award-img.png";
import NationalProcurement from "assets/img/bis/national-procurement-awards-img.png";
import HrLeadership from "assets/img/bis/hr-leadership-and-management-awards-img.png";
import FitAwards from "assets/img/bis/fit-awards-img.png";
import GreenFood from "assets/img/bis/green-food-beverage-producer-awards-img.png";
import BusinessEmergy from "assets/img/bis/business-energy-achievement-awards-img.png";
import { FormGroup, Input, Label } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import PageSpinner from "components/PageSpinner";
import AwardsCard from "components/Containers/Cards/AwardsCard";
import { ToastContainer, toast } from "react-toastify";
import CardBody from "reactstrap/lib/CardBody";
import validator from "validator";

const initialForgotFormData = Object.freeze({
  email: ""
});

const ForgetPasswordPage = props => {
  const [awards, setAwards] = useState();
  const [awardsLoading, setAwardsLoading] = useState(true);
  const [formStatus, setFormStatus] = useState("standby");
  const [forgotFormData, setForgotFormData] = useState(initialForgotFormData);

  const retrieveAwards = async () => {
    const response = fetch("https://businessriver.tv/api/award", {
      //const response = fetch('https://1e30-103-89-237-35.ngrok.io/api/event', {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "omit", // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: "follow", // manual, *follow, error
      referrer: "client" // no-referrer, *client
    })
      .then(response => {
        return response.json();
      })
      .then(events => {
        return events.data;
      });
    return response;
  };

  async function forgotPasswordSubmit(e) {
    e.preventDefault();
    setFormStatus("loading");
    if (validator.isEmail(forgotFormData.email)) {
      const response = await fetch(
        "https://businessriver.tv/api/password/forgot",
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "omit", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
          },
          redirect: "follow", // manual, *follow, error
          referrer: "client", // no-referrer, *client
          body: JSON.stringify(forgotFormData) // body data type must match "Content-Type" header
        }
      );

      const json = await response.json();

      if (json.status == "Success") {
        toast.success(
          "The instructions to reset your password has been sent to your email",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        );
        setFormStatus("standby");
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setFormStatus("standby");
      }
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setFormStatus("standby");
    }
  }

  useEffect(() => {
    const getAwards = async () => {
      const selectedAwards = await retrieveAwards();
      //console.log(selectedAwards);
      setAwards(selectedAwards);
      setAwardsLoading(false);
    };
    getAwards();
  }, []);

  const handleChange = e => {
    setForgotFormData({
      ...forgotFormData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Page
      className="preview"
      title=""
      breadcrumbs={[{ name: "Forgot Password", active: true }]}
    >
      <ToastContainer style={{ width: "370px" }} />
      <Container>
        <Row className="forgot-password-card">
          <Col lg="6">
            <Card>
              <CardBody style={{ padding: "3rem" }}>
                <FormGroup>
                  <p className="forgot-password-question">
                    FORGOT YOUR PASSWORD?
                  </p>
                  <p>Please enter the email you use to sign in.</p>
                  <Label className="mt-4" for="email">
                    <p className="small">Your email</p>
                  </Label>
                  <Input type="email" name="email" onChange={handleChange} placeholder="Email Address"/>
                  <div className="text-center">
                    {formStatus != "standby"
                      ? <Button className="forgot-password-button btn-block mt-3">
                          Request password reset...
                        </Button>
                      : <Button
                          className="forgot-password-button btn-block mt-3"
                          onClick={forgotPasswordSubmit}
                        >
                          Request password reset
                        </Button>}
                    <p className="small bis-link">
                      <a href="/login" className="bis-link">
                        Back to Sign in
                      </a>
                    </p>
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default ForgetPasswordPage;
