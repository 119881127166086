import user1Image from 'assets/img/users/100_1.jpg';
import user2Image from 'assets/img/users/100_2.jpg';
import user3Image from 'assets/img/users/100_3.jpg';
import user4Image from 'assets/img/users/100_4.jpg';
import user5Image from 'assets/img/users/100_5.jpg';

export const notificationsData = [
  {
    id: 1,
    avatar: user1Image,
    name: 'David',
    message: 'sent you a message',
    date: '3 min ago',
  },
  {
    id: 2,
    avatar: user2Image,
    name: 'Jane',
    message: 'mentioned you here',
    date: '1 hour ago',
  },
  {
    id: 3,
    avatar: user3Image,
    name: 'Clark',
    message: 'sent a pull request',
    date: 'yesterday',
  },
  {
    id: 4,
    avatar: user4Image,
    name: 'Alicia',
    message: 'signed up',
    date: '3 days ago',
  },
  {
    id: 5,
    avatar: user5Image,
    name: 'Keith',
    message: 'shared this article',
    date: 'a week ago',
  },
];
