import React, { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import UserPlaceholder from 'assets/img/user-placeholder.png';
import SourceLink from 'components/SourceLink';
import Logo from 'assets/img/logo/logo-dark.png';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';

import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');



const MdMessagesActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>0</small>,
})(MdMessage);

const initialContactData = Object.freeze({
  user_id: '',
  // name: '',
  // email: '',
  subject: '',
  message: ''

});

function Header() {
  const id = "1";
  let history = useHistory();
  const [user, setUser] = useState([]);
  const [isOpenNotificationPopover, setIsOpenNotificationPopover] = useState(false);
  const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(false);

  const [isOpenMessageNotificationPopover, setIsOpenMessageNotificationPopover] = useState(false);
  const [isMessageNotificationConfirmed, setIsMessageNotificationConfirmed] = useState(false);

  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [contactData, setContactData] = useState(initialContactData);
  const [formStatus, setFormStatus] = useState("standby");
  const [notifications, setNotifcations] = useState();
  const [notificationsCount, setNotifcationsCount] = useState(0);

  const MdNotificationsActiveWithBadge = withBadge({
    size: 'md',
    color: 'primary',
    style: {
      top: -10,
      right: -10,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    children: <small>{notificationsCount}</small>,
  })(MdNotificationsActive);

  const retrieveNotifications = async () => {
    const response = fetch('https://businessriver.tv/api/notification?status=Unread&user_id='+currentUser, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const handleClose = (e) => {
    setShowContact(false);
  };

  const handleChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  };

  const handleShowContact = (e) => {
    //e.preventDefault();
    setShowContact(true);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  }

  const toggleNotificationPopover = () => {
    setIsOpenNotificationPopover(!isOpenNotificationPopover);
    setIsOpenUserCardPopover(false);
    setIsMessageNotificationConfirmed(false);
    if (!isNotificationConfirmed) {
      setIsNotificationConfirmed(true);
    }
  }

  const toggleMessageNotificationPopover = () => {
    setIsOpenMessageNotificationPopover(!isOpenMessageNotificationPopover);
    if (!isMessageNotificationConfirmed) {
      setIsMessageNotificationConfirmed(true);
    }
  }

  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
    setIsOpenNotificationPopover(false);
    setIsMessageNotificationConfirmed(false);
  }

  const handleSidebarControlButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
  const location = useLocation()
  var currentPathName = location.pathname
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn']);
  // const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const currentUser = cookies.currentUser;
  // const isLoggedIn = sessionStorage.getItem("IsLoggedIn");
  const isLoggedIn = cookies.isLoggedIn;
  //const userRole = cookies.userRole;

  const [userRole, setUserRole] = useState(cookies.userRole)

  const retrieveUser = async () => {
    const response = fetch('https://businessriver.tv/api/user/' + currentUser, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  async function submitContact(e) {
    e.preventDefault();
    if (contactData.subject != "" && contactData.message != "") {
      setFormStatus("loading");
      // if (validator.isEmail(contactData.email)) {
      const response = await fetch('https://businessriver.tv/api/inquiry', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Content-Type': 'application/json',
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        body: JSON.stringify(contactData), // body data type must match "Content-Type" header
      })

      const json = await response.json();

      if (json.status == "Success") {
        //alert("Award created successfully");
        toast.success('Message sent successful!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setUpdateEntryCommentModal(false);
        // setUpdateEntryCommentModalShow(false);
        setFormStatus("standby");

      } else {
        toast.error('Failed to Submit, please try again!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFormStatus("standby");
      }
      // } else {
      //   //alert("Please fill up all fields");
      //   toast.warn('Please enter a valid email address', {
      //     position: "top-right",
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      //   setFormStatus("standby");
      // } 
    } else {
      toast.error("Please fill up all fields!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    }
  }

  const closePopover = () => {
    setIsOpenUserCardPopover(false);
    setIsOpenNotificationPopover(false);
    setIsMessageNotificationConfirmed(false);
  };

  useEffect(() => {
    const getNotifications = async () => {
      const selectedNotifications = await retrieveNotifications();
      if (selectedNotifications) {
        setNotifcations(selectedNotifications);
        setNotifcationsCount(selectedNotifications.length);
      }
    };
    getNotifications();
  }, []);

  useEffect(() => {
    if (!currentUser) {
      if (currentPathName == "/award/book" || currentPathName == "/award/event" || currentPathName == "/award/book/seats" || currentPathName == "/booking/summary" || currentPathName == "/booking/summary/unlisted" || currentPathName == "/booking/details" || currentPathName == "/booking/payment/success" || currentPathName == "/booking/attendees" || currentPathName == "/booking/attendees/setup" || currentPathName == "/category/winner" || currentPathName == "/award/winner" || currentPathName == "/winner/payment/success") {
      } else {
        history.push("/login");
      }
    } else {
      if (currentUser > 27 && currentPathName != "/maintenance") {
        history.push("/maintenance");
      }
      // if (currentUser) {
      //   const retrieveUser = async () => {
      //     const response = fetch('https://businessriver.tv/api/user/'+currentUser.id, {
      //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //     mode: 'cors', // no-cors, cors, *same-origin
      //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //     credentials: 'omit', // include, *same-origin, omit
      //     headers: {
      //         //'Access-Control-Allow-Origin': "*",
      //         "Access-Control-Allow-Origin" : "*",
      //         "Access-Control-Allow-Credentials" : true ,
      //         'Content-Type': 'application/json',
      //         "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      //         "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      //     },
      //     redirect: 'follow', // manual, *follow, error
      //     referrer: 'client', // no-referrer, *client
      //     })
      //     .then((response) => {
      //       return response.json();
      //     })
      //     .then((events) => {
      //         return events.data;
      //     });
      //     return response;
      //   };

      //   useEffect(() => {
      //     const getUser = async () => {
      //       const selectedUser = await retrieveUser();
      //       //console.log(selectedUser);
      //       setUser(selectedUser);
      //     };
      //     getUser();
      //   }, []);
      // } else {
      //   history.push("/login");
      // }
      const getUser = async () => {
        const selectedUser = await retrieveUser();
        setUser(selectedUser);
        if (selectedUser) {
          setUserRole(selectedUser.role);
        }
        setContactData({
          ...contactData,
          user_id: currentUser
        })
      };

      getUser();
    }
  }, [userRole]);

  const logOut = (e) => {
    setCookies('currentUser', "", { path: '/' });
    setCookies('isLoggedIn', false, { path: '/' });
    setCookies('userRole', "", { path: '/' });
    setCookies('judge', 0, { path: '/' });
    setCookies('judge_id', 0, { path: '/' });
    setCookies('coordinator_id', 0, { path: '/' });
    history.push("/login");
  }

  const profile = (e) => {
    history.push("/profile");
  }


  // useEffect(() => {
  //   if (currentUser) {
  //     setUser(currentUser);
  //   }
  // }, []);

  /*const profilePage = (e) => {
    history.push("/profile");
  }*/

  return (
    <>
      <ToastContainer style={{ width: "370px" }} />
      <Navbar light expand className={bem.b('bg-white')}>
        <Container>
          <Nav navbar className="mr-2">
            {userRole == "Admin" ?
              <a href="/admin" className="navbar-brand d-block mr-auto ml-auto text-black">
                <img
                  src={Logo}
                  width="150"
                  className=""
                  alt="BIS"
                />
              </a>
              :
              <SourceLink className="navbar-brand d-block mr-auto ml-auto text-black">
                <img
                  src={Logo}
                  width="150"
                  className=""
                  alt="BIS"
                />
              </SourceLink>
            }

          </Nav>
          <Nav navbar>
            {/*<SearchInput />*/}
          </Nav>
          <Nav navbar className={bem.e('nav-right')}>
            {!currentUser ?
              <NavItem className="d-inline-flex">
                <NavLink className="position-relative mr-2 text-dark" href="/login">
                  Login
                </NavLink>
                <NavLink className="position-relative mr-2 text-dark" href="/register">
                  Register
                </NavLink>
              </NavItem>
              :
              <NavItem className="d-inline-flex">

                {userRole != "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/">
                    Dashboard
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/admin">
                    Dashboard
                  </NavLink>
                }

                {userRole != "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/awards">
                    Awards
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/admin/awards">
                    Awards
                  </NavLink>
                }
                {userRole == "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/admin/entries">
                    Entries
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/entries">
                    Entries
                  </NavLink>
                }

                {userRole == "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="/admin/bookings">
                    Bookings
                  </NavLink>
                  :
                  <NavLink className="position-relative mr-2 text-dark" href="/bookings">
                    Bookings
                  </NavLink>
                }
                {userRole == "Admin" ?
                  <>
                    <NavLink className="position-relative mr-2 text-dark" href="/admin/users">
                      Users
                    </NavLink>
                    <NavLink className="position-relative mr-2 text-dark dropdown-menu-nav" href="/admin/users">
                      <NavLink className="position-relative p-0 text-dark" href="#">
                        Judging
                      </NavLink>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <NavLink className="position-relative text-dark" href="/admin/judges">
                          Judges
                        </NavLink>
                        <NavLink className="position-relative text-dark" href="/admin/coordinators">
                          Coordinators
                        </NavLink>
                      </div>
                    </NavLink>
                  </>
                  :
                  null
                }
                {userRole != "Admin" ?
                  <NavLink className="position-relative mr-2 text-dark" href="#" onClick={handleShowContact}>
                    Contact Us
                  </NavLink>
                  :
                  null
                }
                <Modal
                  isOpen={showContact}
                  className="modal-preview"
                  fade={false}
                  centered
                  id="edit-judge-modal"
                >
                  <ModalHeader className="text-left">
                    Contact Us
                    <button type="button" className="close react-modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col lg="12">
                        <Card>
                          <CardBody className="card_container">
                            {/* <FormGroup>
                              <Input type="hidden" name="name" onChange={handleChange} value={user.first_name + " " + user.last_name} />
                            </FormGroup>
                            <FormGroup>
                              <Input type="hidden" name="email"  onChange={handleChange} value={user.email} />
                            </FormGroup> */}
                            <FormGroup>
                              <Input type="text" name="subject" onChange={handleChange} placeholder="Subject" />
                            </FormGroup>
                            <FormGroup>
                              <Input type="textarea" rows="5" name="message" onChange={handleChange} placeholder="Message" />
                            </FormGroup>
                          </CardBody>
                          <CardFooter className="text-right">
                            <button type="button" className="btn btn-main btn-main-alt mr-3" data-dismiss="modal" onClick={handleClose}>Close</button>
                            {formStatus == "standby" ?
                              <button type="button" className="btn newbtntheme primary-btn" onClick={submitContact}>Send</button>
                              :
                              <button type="button" className="btn newbtntheme primary-btn" onClick={preventDefault}>Sending...</button>
                            }
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                {/* <NavLink type="button" className="position-relative mr-3 text-dark" data-toggle="modal" data-target="#exampleModalLong">
                Contact Us
              </NavLink>
              <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      ...
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                  </div>
                </div>
              </div> */}
              </NavItem>
            }
            <NavItem className="d-inline-flex">
              <NavLink id="Popover1" className="position-relative mr-2">
                {isNotificationConfirmed ? (
                  <MdNotificationsNone
                    size={25}
                    className="text-secondary can-click"
                    onClick={toggleNotificationPopover}
                  />
                ) : (
                  <MdNotificationsActiveWithBadge
                    size={25}
                    className="text-secondary can-click animated swing infinite"
                    onClick={toggleNotificationPopover}
                  />
                )}
              </NavLink>
              <Popover
                placement="bottom"
                isOpen={isOpenNotificationPopover}
                toggle={toggleNotificationPopover}
                target="Popover1"
                className="notifications-popup"
              >
                <PopoverBody>
                  <Notifications notificationsData={notifications} userRole={userRole} currentUser={currentUser} />
                </PopoverBody>
                <a href="/notifications">
                  <div className='btn newbtntheme border-left-0 border-bottom-0 border-right-0 w-100 text-center'>
                    VIEW ALL
                  </div>
                </a>
              </Popover>
            </NavItem>
            {/* <NavItem className="d-inline-flex">
              <NavLink id="Popover2" className="position-relative">
                {isMessageNotificationConfirmed ? (
                  <MdMessage
                    size={25}
                    className="text-secondary can-click"
                    onClick={toggleMessageNotificationPopover}
                  />
                ) : (
                  <MdMessagesActiveWithBadge
                    size={25}
                    className="text-secondary can-click animated swing infinite"
                    onClick={toggleMessageNotificationPopover}
                  />
                )}
              </NavLink>
              <Popover
                placement="bottom"
                isOpen={isOpenMessageNotificationPopover}
                toggle={toggleMessageNotificationPopover}
                target="Popover2"
              >
                <PopoverBody>
                  <Notifications notificationsData={notificationsData} />
                </PopoverBody>
                </Popover>
            </NavItem> */}
            {currentUser ?
              <NavItem>
                <NavLink id="Popover3">
                  <Avatar
                    onClick={toggleUserCardPopover}
                    className="can-click"
                    src={UserPlaceholder}
                  //src={user.avatar}
                  />
                </NavLink>
                <Popover
                  placement="bottom-end"
                  isOpen={isOpenUserCardPopover}
                  toggle={toggleUserCardPopover}
                  target="Popover3"
                  className="p-0 border-0"
                  style={{ minWidth: 250 }}
                >
                  <PopoverBody className="p-0">
                    <UserCard
                      //title={user.first_name +" "+ user.last_name}
                      title={user.first_name}
                      //title="Administrator"
                      //subtitle="customercare@businessriver.com"
                      //subtitle={currentUser.user.email}
                      //text="Last updated 3 mins ago"
                      className="border-light"
                      avatar={UserPlaceholder}
                    //avatar={user.avatar}
                    >
                      <ListGroup flush>
                        <ListGroupItem tag="button" onClick={profile} action className="" >
                          <MdPersonPin style={{ fontSize: "20px" }} /> Profile
                        </ListGroupItem>
                        {/*<ListGroupItem tag="button" action className="border-light">
                        <MdInsertChart /> Stats
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdMessage /> Messages
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdSettingsApplications /> Settings
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdHelp /> Help
                      </ListGroupItem>*/}
                        <ListGroupItem tag="button" onClick={logOut} action className="">
                          <MdExitToApp style={{ fontSize: "20px" }} /> Logout
                        </ListGroupItem>
                      </ListGroup>
                    </UserCard>
                  </PopoverBody>
                </Popover>
              </NavItem>
              :
              null
            }
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
