import React from 'react';

import { Navbar, Nav, NavItem, Row, Col } from 'reactstrap';

import SourceLink from 'components/SourceLink';

const Footer = () => {
  return (
    <Row className="mt-5">
      <Col>0
        <Navbar className="footer">
        <Nav navbar>
          <NavItem>
          ©{new Date().getFullYear()} BIS
          </NavItem>
        </Nav>
      </Navbar>
      </Col>
    </Row>
    
  );
};

export default Footer;
